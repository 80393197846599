<template>
  <div class="mt-10">
    <v-footer
      tile
      flat
      class="font-weight-medium shadow-md -mt-20"
      :color="$vuetify.theme.dark ? 'dark' : 'primary darken-4'"
      :dark="$vuetify.theme.dark"
      :inset="true"
      v-if="hiddenAppBar"
    >
      <v-container class="">
        <v-row class="mt-20">
          <v-col cols="12" md="4">
            <h6 class="white--text mb-0">Sobre nós</h6>
            <p class="body-2 grey--text text--lighten-2">
              AtleticMe: A plataforma ideal para universitários e suas
              atléticas. Nosso sistema inovador transforma a organização e a
              participação em eventos, priorizando simplicidade, segurança e
              controle. Com AtleticMe, você obtém informações detalhadas para
              uma gestão eficiente e uma experiência excepcional. Eleve seus
              eventos universitários a um novo patamar com AtleticMe!
            </p>
            <p class="grey--text text--lighten-2">
              Desenvolvido com
              <v-icon small color="danger">mdi-heart</v-icon> por
              <a
                class="grey--text text--lighten-2 font-weight-medium"
                target="_blank"
                href="https://www.instagram.com/gabrielpastori_1/"
              >
                Gabriel Pastori
              </a>
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <h6 class="white--text">Explorar</h6>
            <div class="mb-2">
              <router-link to="/app" class="grey--text text--lighten-2">
                Home
              </router-link>
            </div>
            <!-- Termos & Condições -->
            <div class="mb-2">
              <router-link
                to="/terms/contract"
                class="grey--text text--lighten-2"
              >
                Termos & Condições
              </router-link>
            </div>
            <div class="mb-2">
              <router-link
                to="/terms/privacy"
                class="grey--text text--lighten-2"
              >
                Politica de Privacidade
              </router-link>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <h6 class="white--text">Entre em contato com a gente!</h6>
            <div class="d-flex mb-2" style="gap: 0.5rem">
              <v-btn
                target="_blank"
                href="mailto:contato@atleticme.app"
                small
                color="primary"
              >
                <v-icon small left>mdi-email</v-icon>
                Email
              </v-btn>
              <v-btn
                target="_blank"
                :href="`//wa.me/${whatsapp}`"
                small
                color="primary"
              >
                <v-icon small left>mdi-whatsapp</v-icon>
                Whatsapp
              </v-btn>
              <v-btn
                fab
                dark
                x-small
                color="#ec412c"
                target="_blank"
                href="https://www.instagram.com/ticketme.app/"
                aria-label="Instagram"
              >
                <v-icon small dark>mdi-instagram</v-icon>
              </v-btn>
              <!-- <v-btn class="mx-2" fab dark x-small color="#3765c9">
              <v-icon small dark>mdi-facebook</v-icon>
            </v-btn>
            <v-btn class="mx-2" fab dark x-small color="#039ff5">
              <v-icon small dark>mdi-twitter</v-icon>
            </v-btn> -->
            </div>
          </v-col>
        </v-row>
        <!-- <v-row class="mt-4">
        <v-col cols="12" md="6"> </v-col>
      </v-row> -->
      </v-container>
    </v-footer>
  </div>
</template>
<script>
const { VUE_APP_TICKETME_WHATSAPP } = process.env;

export default {
  name: "Footer",
  data: () => ({
    whatsapp: VUE_APP_TICKETME_WHATSAPP,
  }),
  computed: {
    hiddenAppBar() {
      return this.$route.meta.hiddenAppBar || false;
    },
  },
};
</script>
